*::-webkit-scrollbar {
    width: 8px
}

*::-webkit-scrollbar-track {
    background-color: #202020;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 10px;
    /* backgroundColor: "red", */
        /* borderRadius: 2 */
} 