.Toastify__toast--success {
    border: 1px solid #57B183 !important;
    border-radius: 12px !important;
    background: #121B16 !important;
    font-weight: 600;
}


.Toastify__toast--success::after {
    color: #fff;
}



.Toastify__toast--info {
    border: 1px solid #38CDFF !important;
    border-radius: 12px !important;
    background: #0F1E22 !important;
    font-weight: 600;
}


.Toastify__toast--info::after {
    color: #fff;
}



.Toastify__toast--warning {
    border: 1px solid #FFBB49 !important;
    border-radius: 12px !important;
    background: #231C10 !important;
    font-weight: 600;
}


.Toastify__toast--warning::after {
    color: #fff;
}


.Toastify__toast--error {
    border: 1px solid #F55F71 !important;
    border-radius: 12px !important;
    background: #221314 !important;
    font-weight: 600;
}


.Toastify__toast--error::after {
    color: #fff;
}